<template>
  <v-row>
    <v-col cols="12" class="tertiary--text subtitle-1 font-weight-bold pb-0">
      {{ label }}
      <v-btn
        icon
        color="teal"
        v-if="!editing && isEditable"
        :disabled="disabled || loading"
        @click="$emit('click:edit', index)"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" class="pt-0">
      <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
      <template v-else>
        <slot v-if="!editing" name="value" :value="value">
          <span class="grey--text body-2">
            {{ value || "-" }}
          </span>
        </slot>
        <div v-else :class="['d-flex align-start', actionContainerClass]">
          <validation-provider slim :name="label" :rules="rules" v-slot="{ errors }">
            <slot name="default" :errors="errors" />
          </validation-provider>
          <div class="ml-2">
            <v-btn v-if="!hideSave" icon color="teal" :loading="saving" @click="$emit('click:save', index)">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn icon color="red" :disabled="saving" @click="$emit('click:cancel')">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: "ProfileField",
    props: {
      value: [String, Array, Object, Boolean, Number],
      isEditable: Boolean,
      loading: Boolean,
      saving: Boolean,
      label: String,
      rules: [String, Object],
      index: Number,
      activeIndex: Number,
      hideSave: Boolean,
      actionContainerClass: [String, Object]
    },
    computed: {
      editing() {
        return this.activeIndex === this.index;
      },
      disabled() {
        return this.activeIndex > -1 && this.activeIndex !== this.index;
      }
    }
  };
</script>
